import React from 'react'

import { ContactUsForm } from './contact-us-form'
import * as styles from './index.module.css'
import { SectionHeader } from './section-header'
import { Sidebar } from './sidebar'

export function ContactUs(props) {
  return (
    <div>
      <SectionHeader />

      <div className={styles.contantWrapper}>
        <div className={styles.sidebar}>
          <Sidebar />
        </div>

        <div className={styles.contactUsForm}>
          <ContactUsForm />
        </div>
      </div>
    </div>
  )
}
