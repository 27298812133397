import React from 'react'

import { ContactSidebarCard } from '../contact-sidebar-card'

export function Sidebar() {
  return (
    <div>
      <ContactSidebarCard />
    </div>
  )
}
