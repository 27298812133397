import React from 'react'

export function Success() {
  return (
    <div>
      <p>
        Thank you for reaching out to Old Biscayne Designs. If you have
        questions that need immediate answers, Please contact our Customer
        Service Representatives at Otherwise please allow one (1) business day
        for a response. +1-­800-­397-­5397.
      </p>
      <p>Thank you for contacting Old Biscayne.</p>
    </div>
  )
}
