/* eslint-disable jsx-a11y/no-onchange */
import axios from 'axios'
import React, { useState } from 'react'

import { Success } from './success'
import { Error } from './error'
import * as styles from './contact-us-form.module.css'

const url = `${process.env.GATSBY_OLD_API_URL}/contact-us`

const Status = {
  error: 'ERROR',
  success: 'SUCCESS',
}

export function ContactUsForm() {
  const [inputs, setInputs] = useState({
    company: '',
    firstname: '',
    lastname: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    comment: '',
  })

  const [status, setStatus] = useState(null)

  const handleInputChange = (event) => {
    event.persist()
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: url,
      data: JSON.stringify(inputs),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        setStatus(Status.success)
      })
      .catch(function (response) {
        setStatus(Status.error)
      })
  }

  return (
    <div>
      {status === null && (
        <form
          className={styles.contactForm}
          onSubmit={handleSubmit}
          method="post"
        >
          <div className={styles.contactFormTitle}>Contact Us Directly</div>
          <div>
            <label className={`${styles.contactFormLabel}`} htmlFor="company">
              Company
            </label>
            <input
              className={`${styles.contactFormInput} ${styles.contactFormCompany}`}
              type="text"
              id="company"
              name="company"
              onChange={handleInputChange}
              value={inputs.company}
            />
            <div className={styles.fieldGroup}>
              <div
                className={`${styles.contactFormFirstName} ${styles.contactFormGroup}`}
              >
                <label
                  className={`${styles.contactFormLabel}`}
                  htmlFor="firstname"
                >
                  First Name
                </label>
                <input
                  className={`${styles.contactFormInput}`}
                  type="text"
                  id="firstname"
                  name="firstname"
                  onChange={handleInputChange}
                  value={inputs.firstname}
                />
              </div>
              <div
                className={`${styles.contactFormLastName} ${styles.contactFormGroup}`}
              >
                <label
                  className={`${styles.contactFormLabel}`}
                  htmlFor="lastname"
                >
                  Last Name
                </label>
                <input
                  className={`${styles.contactFormInput}`}
                  type="text"
                  id="lastname"
                  name="lastname"
                  onChange={handleInputChange}
                  value={inputs.lastname}
                />
              </div>
            </div>
            <div
              className={`${styles.contactFormAddress} ${styles.contactFormGroup}`}
            >
              <label className={`${styles.contactFormLabel}`} htmlFor="address">
                Address
              </label>
              <input
                className={`${styles.contactFormInput}`}
                type="text"
                id="address"
                name="address"
                onChange={handleInputChange}
                value={inputs.address}
              />
            </div>
            <div
              className={`${styles.contactFormCity} ${styles.contactFormGroup}`}
            >
              <label className={`${styles.contactFormLabel}`} htmlFor="city">
                City
              </label>
              <input
                className={`${styles.contactFormInput}`}
                type="text"
                id="city"
                name="city"
                onChange={handleInputChange}
                value={inputs.city}
              />
            </div>
            <div className={styles.fieldGroup}>
              <div
                className={`${styles.contactFormState} ${styles.contactFormGroup}`}
              >
                <label className={`${styles.contactFormLabel}`} htmlFor="state">
                  State
                </label>
                <select
                  className={`${styles.contactFormInput}`}
                  type="text"
                  id="state"
                  name="state"
                  onChange={handleInputChange}
                  value={inputs.state}
                >
                  <option>&nbsp;</option>
                  <option value="AL">ALABAMA</option>
                  <option value="AK">ALASKA</option>
                  <option value="AS">AMERICAN SAMOA</option>
                  <option value="AZ">ARIZONA</option>
                  <option value="AR">ARKANSAS</option>
                  <option value="CA">CALIFORNIA</option>
                  <option value="CO">COLORADO</option>
                  <option value="CT">CONNECTICUT</option>
                  <option value="DE">DELAWARE</option>
                  <option value="DC">DISTRICT OF COLUMBIA</option>
                  <option value="FM">FEDERATED STATES OF MICRONESIA</option>
                  <option value="FL">FLORIDA</option>
                  <option value="GA">GEORGIA</option>
                  <option value="GU">GUAM</option>
                  <option value="HI">HAWAII</option>
                  <option value="ID">IDAHO</option>
                  <option value="IL">ILLINOIS</option>
                  <option value="IN">INDIANA</option>
                  <option value="IA">IOWA</option>
                  <option value="KS">KANSAS</option>
                  <option value="KY">KENTUCKY</option>
                  <option value="LA">LOUISIANA</option>
                  <option value="ME">MAINE</option>
                  <option value="MH">MARSHALL ISLANDS</option>
                  <option value="MD">MARYLAND</option>
                  <option value="MA">MASSACHUSETTS</option>
                  <option value="MI">MICHIGAN</option>
                  <option value="MN">MINNESOTA</option>
                  <option value="MS">MISSISSIPPI</option>
                  <option value="MO">MISSOURI</option>
                  <option value="MT">MONTANA</option>
                  <option value="NE">NEBRASKA</option>
                  <option value="NV">NEVADA</option>
                  <option value="NH">NEW HAMPSHIRE</option>
                  <option value="NJ">NEW JERSEY</option>
                  <option value="NM">NEW MEXICO</option>
                  <option value="NY">NEW YORK</option>
                  <option value="NC">NORTH CAROLINA</option>
                  <option value="ND">NORTH DAKOTA</option>
                  <option value="MP">NORTHERN MARIANA ISLANDS</option>
                  <option value="OH">OHIO</option>
                  <option value="OK">OKLAHOMA</option>
                  <option value="OR">OREGON</option>
                  <option value="PW">PALAU</option>
                  <option value="PA">PENNSYLVANIA</option>
                  <option value="PR">PUERTO RICO</option>
                  <option value="RI">RHODE ISLAND</option>
                  <option value="SC">SOUTH CAROLINA</option>
                  <option value="SD">SOUTH DAKOTA</option>
                  <option value="TN">TENNESSEE</option>
                  <option value="TX">TEXAS</option>
                  <option value="UT">UTAH</option>
                  <option value="VT">VERMONT</option>
                  <option value="VI">VIRGIN ISLANDS</option>
                  <option value="VA">VIRGINIA</option>
                  <option value="WA">WASHINGTON</option>
                  <option value="WV">WEST VIRGINIA</option>
                  <option value="WI">WISCONSIN</option>
                  <option value="WY">WYOMING</option>
                </select>
              </div>
              <div
                className={`${styles.contactFormZip} ${styles.contactFormGroup}`}
              >
                <label className={`${styles.contactFormLabel}`} htmlFor="zip">
                  Zip
                </label>
                <input
                  className={`${styles.contactFormInput}`}
                  type="text"
                  id="zip"
                  name="zip"
                  onChange={handleInputChange}
                  value={inputs.zip}
                />
              </div>
            </div>
            <div
              className={`${styles.contactFormEmail} ${styles.contactFormGroup}`}
            >
              <label className={`${styles.contactFormLabel}`} htmlFor="email">
                Email Address
              </label>
              <input
                className={`${styles.contactFormInput}`}
                type="text"
                id="email"
                name="email"
                onChange={handleInputChange}
                value={inputs.email}
              />
            </div>
            <div className={`${styles.contactFormGroup}`}>
              <label
                className={`${styles.contactFormLabel}`}
                htmlFor="comments"
              >
                Comments
              </label>
              <textarea
                className={`${styles.contactFormInput}`}
                id="comments"
                name="comments"
                onChange={handleInputChange}
                value={inputs.comments}
              />
            </div>
            <div className={`${styles.fieldGroup}`}>
              <button
                className={`${styles.contactFormSubmitButton}`}
                type="submit"
                value="submit"
              >
                Submit
              </button>
              <button
                className={`${styles.contactFormResetButton}`}
                type="reset"
                value="reset"
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      )}

      {status === Status.success && <Success />}
      {status === Status.error && <Error />}
    </div>
  )
}
