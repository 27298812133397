import React from 'react'

import { MainLayout } from '../../layouts/main'
import { ContactUs } from '../../components/contact-us'

export default function DealersPage() {
  return (
    <MainLayout>
      <ContactUs />
    </MainLayout>
  )
}
